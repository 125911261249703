//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CurationList from "@/components/CurationList";
import { curation_service } from "@/services";
export default {
  props: {
    emptyTitle: {
      type: String,
      default: "Nothing to show here",
    },
    emptyDesc: {
      type: String,
      default: "No movies found!",
    },
    emptyIcon: {
      type: String,
      default: null,
    },
    emptyImage: {
      type: String,
      default: null,
    },
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    CurationList,
  },
  data() {
    return {
      // list of movielists of the profile
      recommend_lists: [],
      selected_recommend_list: {},
    };
  },
  computed: {
    menu_options() {
      if (this.is_my_profile)
        return [{ name: "Remove", icon: "mdi-trash-can", emit: "remove" }];
      return [];
    },
    is_my_profile() {
      return this.profile.id == this.my_profile.id;
    },
  },
  watch: {
    profile() {
      this.fetch_contest_recommend_lists();
    },
  },
  created() {
    this.fetch_contest_recommend_lists();
  },
  methods: {
    on_recommend_list_selected(recommend_list) {
      this.selected_recommend_list = recommend_list;
    },
    fetch_contest_recommend_lists() {
      if (this.profile.id)
        curation_service
          .get({ owner__id: this.profile.id, contest__isnull: "false" })
          .then((data) => {
            this.recommend_lists = data.results;
            this.selected_recommend_list = this.recommend_lists[0];
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
